import { Mark, getMarksBetween, findParentNodeClosestToPos } from '@tiptap/core';

const CustomText = Mark.create({
    name: "customText",
    excludes: "",
    priority: 999999,
    inclusive: false,
    addAttributes() {
        return {
            xbrlId: {
                default: null,
                renderHTML: (attributes) => {
                    if(attributes.xbrlId) {
                        return {
                            "data-xbrl-id": attributes.xbrlId,
                        };
                    };
                },
                parseHTML: (element) => element.getAttribute("data-xbrl-id")
            },
            selected: {
                default: null,
                renderHTML: (attributes) => {
                    if(attributes.selected) {
                        return {
                            "data-selected": attributes.selected,
                        };
                    };
                },
            },
            cssClass: {
                default: "mark-tag",
                renderHTML: (attributes) => {
                    if(attributes.xbrlId) {
                        return {
                            "class": attributes.cssClass,
                        };
                    };
                }
            },
        }
    },
    renderHTML: ({ HTMLAttributes }) => {
        return ["span", HTMLAttributes, 0];
    },
    parseHTML: () => {
        return [
            {
                tag: "span",
                getAttrs: (el) => {
                    let xbrlId = el.getAttribute("data-xbrl-id");
                    if (xbrlId !== null && xbrlId !== undefined && xbrlId !== "") return {};
                    return false;
                },
            },
        ];
    },
    addCommands() {
        return {
            removeMarkTagById: (id) => ({tr, chain}) => {
                let { doc } = tr;
                let start = tr.selection.$from.start(0);
                let end = tr.selection.$from.end(0);
                let markTags = getMarksBetween(start, end, doc);
                let markTagWithPos = markTags.find((markWithPos) => markWithPos.mark.attrs?.xbrlId === id);
                if (markTagWithPos) return chain().setTextSelection(markTagWithPos.from).removeClosestMarkTag(() => {}, markTagWithPos.mark.attrs.xbrlId).run();
                return false;
            },
            removeClosestMarkTag: (cb, id) => ({ tr, chain, commands }) => {
                if ( commands.isSelectionInOneNode() ){
                    let resolvedPos = tr.selection.$from;
                    let parentNode = findParentNodeClosestToPos(tr.selection.$from, (node) => {
                        return ["paragraph", "heading"].includes(node?.type?.name);
                    });
                    let start = resolvedPos.start(parentNode.node.depth);
                    let end = resolvedPos.end(parentNode.node.depth);
                    let foundMarks = getMarksBetween(start, end, tr.doc).filter((markWithPos) => {
                        return markWithPos.mark?.attrs?.xbrlId
                    });
                    let markTags = [];
                    for (let markWithPos of foundMarks){
                        let parentGroup = markTags.findLast((markTagGroup) => {
                            for (let markTag of markTagGroup){
                                if (markTag.from !== markWithPos.from && markTag.to !== markWithPos.to && markTag.mark.attrs.xbrlId === markWithPos.mark.attrs.xbrlId){
                                    return true;
                                }
                            }
                            return false;
                        });
                        if (parentGroup){
                            for (let markTag of parentGroup){
                                if (markTag.from <= markWithPos.from && markTag.to === markWithPos.from && markTag.mark.attrs.xbrlId === markWithPos.mark.attrs.xbrlId){
                                    markTag.to = markWithPos.to;
                                }
                            }
                        }else{
                            let parentGroup = markTags.findLast((markTagGroup) => {
                                for (let markTag of markTagGroup){
                                    if (markTag.from <= markWithPos.from || markTag.to >= markWithPos.to && markTag.mark.attrs.xbrlId !== markWithPos.mark.attrs.xbrlId){
                                        return true;
                                    }
                                }
                                return false;
                            })
                            if (parentGroup){
                                let parentDepth = markTags.indexOf(parentGroup);
                                if (parentDepth + 1 < markTags.length ){
                                    markTags[parentDepth + 1].push(markWithPos);
                                }else{
                                    markTags.push([markWithPos]);
                                }
                            }else{
                                markTags.push([markWithPos]);
                            }
                        }
                    }
                    let keepGoing = true;
                    keepGoing = keepGoing && chain().setTextSelection({from: start, to: end}).unsetMark("customText").run();
                    for (let markTagGroup of markTags){
                        for (let markTag of markTagGroup){
                            if (markTag.mark.attrs.xbrlId !== id){
                                keepGoing = keepGoing && chain().setTextSelection({from: markTag.from, to: markTag.to}).setMark("customText", markTag.mark.attrs).run();
                            }
                        }
                    }
                    return keepGoing;
                }else {
                    cb();
                    return false;
                }
            },
            isSelectionInOneNode: () => ({ state }) => {
                let { selection } = state;
                let deepestNodeFrom = findParentNodeClosestToPos(selection.$from, (node) => {
                    if (node.type.name === "paragraph" || node.type.name === "heading"){
                        return true;
                    }
                    return false;
                });
                let deepestNodeTo = findParentNodeClosestToPos(selection.$to, (node) => {
                    if (node.type.name === "paragraph" || node.type.name === "heading"){
                        return true;
                    }
                    return false;
                });
                if (deepestNodeFrom.pos === deepestNodeTo.pos && deepestNodeFrom.start === deepestNodeTo.start 
                && deepestNodeFrom.node.textContent === deepestNodeTo.node.textContent){ // selection doesn't span across multiple nodes (for ex. paragraphs / headings)
                    return true;
                }
                return false
            },
            updateMarkTag: (attrs, range) => ({ tr, chain }) => {
                let parentNode = null;
                let resolvedPos = null;
                if (range){ //find correct mark by id and update it with attrs
                    resolvedPos = tr.doc.resolve(range.from);
                    parentNode = findParentNodeClosestToPos(resolvedPos, (node) => {
                        return ["paragraph", "heading"].includes(node?.type?.name);
                    });
                }else{//find correct mark by cursor / selection and update it with attrs
                    resolvedPos = tr.selection.$from;
                    parentNode = findParentNodeClosestToPos(tr.selection.$from, (node) => {
                        return ["paragraph", "heading"].includes(node?.type?.name);
                    });
                }
                let start = resolvedPos.start(parentNode.node.depth);
                let end = resolvedPos.end(parentNode.node.depth);
                let foundMarks = getMarksBetween(start, end, tr.doc).filter((markWithPos) => {
                    return markWithPos.mark?.attrs?.xbrlId
                });
                let markTags = [];
                for (let markWithPos of foundMarks){
                    let parentGroup = markTags.findLast((markTagGroup) => {
                        for (let markTag of markTagGroup){
                            if (markTag.from !== markWithPos.from && markTag.to !== markWithPos.to && markTag.mark.attrs.xbrlId === markWithPos.mark.attrs.xbrlId){
                                return true;
                            }
                        }
                        return false;
                    });
                    if (parentGroup){
                        for (let markTag of parentGroup){
                            if (markTag.from <= markWithPos.from && markTag.to === markWithPos.from && markTag.mark.attrs.xbrlId === markWithPos.mark.attrs.xbrlId){
                                markTag.to = markWithPos.to;
                            }
                        }
                    }else{
                        let parentGroup = markTags.findLast((markTagGroup) => {
                            for (let markTag of markTagGroup){
                                if (markTag.from <= markWithPos.from || markTag.to >= markWithPos.to && markTag.mark.attrs.xbrlId !== markWithPos.mark.attrs.xbrlId){
                                    return true;
                                }
                            }
                            return false;
                        })
                        if (parentGroup){
                            let parentDepth = markTags.indexOf(parentGroup);
                            if (parentDepth + 1 < markTags.length ){
                                markTags[parentDepth + 1].push(markWithPos);
                            }else{
                                markTags.push([markWithPos]);
                            }
                        }else{
                            markTags.push([markWithPos]);
                        }
                    }
                }
                let keepGoing = true;
                keepGoing = keepGoing && chain().setTextSelection({from: start, to: end}).unsetMark("customText").run();
                for (let markTagGroup of markTags){
                    for (let markTag of markTagGroup){
                        if (markTag.mark.attrs.xbrlId === attrs.xbrlId){
                            keepGoing = keepGoing && chain().setTextSelection({from: markTag.from, to: markTag.to}).setMark("customText", attrs).run();
                        }else{
                            keepGoing = keepGoing && chain().setTextSelection({from: markTag.from, to: markTag.to}).setMark("customText", markTag.mark.attrs).run();
                        }
                    }
                }
                return keepGoing;
            }
        }
    }
});

export default CustomText;