<template>
    <node-view-wrapper class="page-header">
        <node-view-content></node-view-content>
    </node-view-wrapper>
</template>

<script>
import { NodeViewWrapper, NodeViewContent } from "@tiptap/vue-2";

export default {
    name: "HeaderView",
    components: {
        NodeViewWrapper,
        NodeViewContent,
    },
    props: {
        node: {
            type: Object,
            // required: true,
        },
        selected: {
            type: Boolean,
        },
        deleteNode: {
            type: Function,
        },
    },
    beforeDestroy() {
        // this.deleteNode();
    },
};
</script>

<style lang="scss">
.page-header {
    width: 100%;
    line-height: 1.3em;
    &:after {
        content: ".";
        display: block;
        height: 0;
        clear: both;
        visibility: hidden;
    }
    &.has-focus {
        background-color: lightgray;
    }
}
</style>
