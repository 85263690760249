<template>
    <node-view-wrapper
        class="tableWrapper"
        :class="{ 'selected-outline': this.selected }"
        :style="{ float: displayMode, zIndex: this.selected ? 1 : 'auto' }"
    >
        <div data-drag-handle draggable="true" class="drag-handle" @click="selectTable()">
            <v-icon size="20" color="primary">
                mdi-drag
            </v-icon>
        </div>
        <table :data-saved-before="node.attrs.savedBefore" :data-float="node.attrs.float" :xbrlId="node.attrs.xbrlId">
            <colgroup>
                <!-- eslint-disable-next-line --><!-- prettier-ignore -->
                <col v-for="(item, index) in colWidths" :style="{width: `${item}px`}">
            </colgroup>
            <node-view-content as="tbody"> </node-view-content>
        </table>
        <div class="image-menu d-flex flex-column" :class="{ visible: selected }">
            <v-btn-toggle v-model="selectedButton" dense class="image-menu-buttons">
                <v-btn title="No wrap">
                    <v-icon>mdi-format-wrap-top-bottom</v-icon>
                </v-btn>

                <v-btn title="Wrap right">
                    <v-icon style="transform: rotateY(180deg);">mdi-format-wrap-inline</v-icon>
                </v-btn>
                <v-btn title="Wrap left">
                    <v-icon>mdi-format-wrap-inline</v-icon>
                </v-btn>
            </v-btn-toggle>
        </div>
    </node-view-wrapper>
</template>

<script>
import { NodeViewWrapper, NodeViewContent } from "@tiptap/vue-2";
export default {
    name: "TableView",
    components: {
        NodeViewWrapper,
        NodeViewContent,
    },
    props: {
        node: {
            type: Object,
            // required: true,
        },
        editor: {
            type: Object,
        },
        selected: {
            type: Boolean,
        },
        deleteNode: {
            type: Function,
        },
        getPos: {
            type: Function,
        },
    },
    data() {
        return {
            selectedButton: 0,
            displayMode: null,
        };
    },
    created() {
        switch (this.node.attrs.float) {
            case "right":
                this.selectedButton = 1;
                break;
            case "left":
                this.selectedButton = 2;
                break;
            default:
                this.selectedButton = 0;
        }
    },
    computed: {
        colWidths: function() {
            let rows = this.node.content.content;
            let colWidths = [];
            if (rows) {
                let cols = rows[0].content.content;
                for (let col of cols) {
                    if (col.attrs.colwidth) {
                        for (let colWidth of col.attrs.colwidth) {
                            colWidths.push(colWidth);
                        }
                    }
                }
            }
            return colWidths;
        },
    },
    watch: {
        selectedButton: function(oldVal, newVal) {
            if (oldVal !== newVal) {
                switch (this.selectedButton) {
                    case 1:
                        this.updateAttributes({ float: "right" });
                        this.displayMode = "right";
                        break;
                    case 2:
                        this.updateAttributes({ float: "left" });
                        this.displayMode = "left";
                        break;
                    default:
                        this.updateAttributes({ float: null });
                        this.displayMode = null;
                }
            }
        },
    },
    methods: {
        selectTable() {
            this.editor.commands.setNodeSelection(this.getPos());
        },
    },
    renderHTML: ({ HTMLAttributes, node }) => {
        let rows = node.content.content;
        let colWidths = [];
        if (rows) {
            let cols = rows[0].content.content;
            for (let col of cols) {
                if (col.attrs.colwidth) {
                    for (let colWidth of col.attrs.colwidth) {
                        colWidths.push(colWidth);
                    }
                }
            }
        }
        return [
            "table",
            ["colgroup", ...colWidths.map((colWidth) => ["col", { style: `width: ${colWidth}px` }])],
            ["tbody", HTMLAttributes, 0],
        ];
    },
};
</script>

<style lang="scss">
.tableWrapper {
    max-width: 100%;
    position: relative;
    &:hover {
        table {
            td {
                outline: 0.5px solid grey;
            }
        }
    }
    .drag-handle {
        position: absolute;
        z-index: 1;
        top: 0;
        left: -20px;
        cursor: pointer;
    }
}

.selected-outline {
    outline: 2px solid blue;
}
.image-menu {
    position: absolute;
    top: 0;
    right: -70px;
    visibility: hidden;
    &.visible {
        z-index: 1;
        visibility: visible;
    }
}

.image-menu-buttons {
    flex-direction: column;
}
</style>
