<template>
    <node-view-wrapper class="page-footer">
        <node-view-content></node-view-content>
    </node-view-wrapper>
</template>

<script>
import { NodeViewWrapper, NodeViewContent } from "@tiptap/vue-2";

export default {
    name: "FooterView",
    components: {
        NodeViewWrapper,
        NodeViewContent,
    },
    props: {
        node: {
            type: Object,
            required: true,
        },
        deleteNode: {
            type: Function,
        },
    },
    beforeDestroy() {
        // this.deleteNode();
    },
};
</script>
<style lang="scss">
.page-footer {
    line-height: 1.3em;
    position: absolute;
    bottom: 1cm;
    left: 1.8cm;
    right: 1.8cm;
    &:after {
        content: ".";
        display: block;
        height: 0;
        clear: both;
        visibility: hidden;
    }

    &.has-focus {
        background-color: lightgray;
    }
}
</style>
